import React from 'react';
import PhoneDico from './phone/Dico';
import PcDico from './pc/Dico';

const Dico = (props) => {
  let devices = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  return (<>
    {devices ? <PcDico /> : <PhoneDico />}
  </>
  )
}

export default Dico