
import React from 'react';
import Style from './css/Dico.module.scss';
import img_fdico from '../../img/img_fdico.jpg';
import ic_fdico_logo from '../../img/ic_fdico_logo.jpg';
import ic_fun1 from '../../img/ic_fun1.jpg';
import ic_fun2 from '../../img/ic_fun2.jpg';
import ic_fun3 from '../../img/ic_fun3.jpg';
import img_fdicobg from '../../img/img_fdicobg.png';
const Dico = (props) => {
  return (
    <div className={Style.box}>
      <img className={Style.bg} src={img_fdicobg} alt="img" />
      <div className={Style.logo}>
        <img src={ic_fdico_logo} alt="img" />
        <span>尚智筹</span>
      </div>
      <div className={Style.content}>
        <img className={Style.interface} src={img_fdico} alt="img" />
        <div className={Style.intr}>
          <span></span>
          <span>创造者众筹预售的私域社区</span>
          <span></span>
        </div>
        <span className={Style.intr_en}>for dream-maker come out </span>
        <span className={Style.title}>尚智筹</span>
        <span className={Style.fun}>一款为创造者提供众筹预售的私域社区致力于连接爱好创造与创新者。</span>
        <span className={Style.fun}>在这里，创造者既可发起众筹，又可通过订阅拓展私域为新品做预热推广。</span>
        <span className={Style.fun}>尚智筹帮助创造者保护创意并释放创新热情，高效绽放每一位的差异化。</span>
        <div className={Style.feature}>
          <img src={ic_fun1} alt="img" />
          <span>时尚社区</span>
        </div>
        <div className={Style.feature}>
          <img src={ic_fun2} alt="img" />
          <span>预售众筹</span>
        </div>
        <div className={Style.feature}>
          <img src={ic_fun3} alt="img" />
          <span>遇见创意</span>
        </div>
      </div>
    </div>
  )
}

export default Dico