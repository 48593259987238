import React from 'react';
import PhoneRainbow from './phone/Rainbow';
import PcRainbow from './pc/Rainbow';

const Rainbow = (props) => {
  let devices = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  return (<>
    {devices ? <PcRainbow /> : <PhoneRainbow />}
  </>
  )
}

export default Rainbow