import React from 'react';
import "lib-flexible";
import "./css/use.css";
import { HashRouter as Router, Route } from "react-router-dom";
import Home from "./pager/Home";
import DigitalTwin from "./pager/DigitalTwin";
import Business from "./pager/Business";
import Commodity from "./pager/Commodity";
import Ifsc from "./pager/ifsc/Ifsc";
import Cws from "./pager/Cws";
import NewIfsc from "./pager/NewIfsc";
import Dico from "./pager/Dico";
import Rainbow from "./pager/Rainbow";

function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/pager/DigitalTwin" component={DigitalTwin} />
      <Route exact path="/pager/Business" component={Business} />
      <Route exact path="/pager/Commodity" component={Commodity} />
      <Route exact path="/pager/Ifsc" component={Ifsc} />
      <Route exact path="/pager/Cws" component={Cws} />
      <Route exact path="/pager/NewIfsc" component={NewIfsc} />
      <Route exact path="/pager/Dico" component={Dico} />
      <Route exact path="/pager/Rainbow" component={Rainbow} />
    </Router>
  );
}

export default App;
