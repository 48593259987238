import React from 'react';
import Style from './css/Rainbow.module.scss';
import img_phone from '../../img/img_phonebg.jpg';
import ic_rainbow_logo from '../../img/ic_rainbow_logo.png';


const Rainbow = (props) => {
  return (
    <div className={Style.box}>
      <img className={Style.bg} src={img_phone} alt="alt" />
      <div>
        <div className={Style.logo}>
          <img src={ic_rainbow_logo} alt='alt' />
          <span>易连</span>
        </div>
        <div className={Style.logo_intr}>
          <span></span>
          <span>你的数据智能助理</span>
          <span></span>
        </div>
      </div>
      <div className={Style.content}>
        <span className={Style.title}>易连--你的私人数智小管家</span>
        <div className={Style.intr}>
          <span>易连是一款帮助用户从私有数据中挖掘价值的智能边缘设备，采用零代码方式，结合“AI”，“可见可信”和“高维分析模型”等前沿技术。</span>
          <span>通过持续不断的释放私有数据的潜在价值，让你的日常生活与工作更轻松、更便捷。</span>
        </div>
        <span className={Style.title_en}>QRainbow--your private data smart agent steward.</span>
        <div className={Style.intr_en}>
          <span>QRainbow is an intelligent edge device designed to help people extract value from personal private data. It utilizes cutting- edge technologies such as Artificial Intelligence ,trustworthy date processing, and high-dimensional analytical models,which deployed by no-code method. By continuously accelerating by potential value from those data mine, QRainbow is going to leverage your daily life and work easier and more convenient.</span>
        </div>

      </div>
      <div className={Style.feature}>
        <span>私人知识库</span>
        <span>蓝图超智助理</span>
        <span>数字分身</span>
      </div>
    </div>
  )
}

export default Rainbow